export const messageJSON = {
    "MSGI001":"登録しました。",
    "MSGI002":"%1を更新しました。",
    "MSGI003":"%1を削除します。よろしいですか？",
    "MSGI004":"%1を削除しました。",
    "MSGI005":"%1のダウンロードを開始します。よろしいですか？",
    "MSGI006":"変更しました。",
    "MSGI007":"メールを送信しました。",
    "MSGI008":"入力中のデータは破棄されますが、よろしいですか？",
    "MSGI009":"%1しますか？",
    "MSGI010":"情報システム部にパスワードリセット依頼メールを送信します。",
    "MSGI011":"削除しました。",
    "MSGI012":"一致する検索結果がありません。",
    "MSGI013":"選択中ロケーションの全在庫を一括で移動出庫登録します。\n（移動可能数分）続行する場合は「登録」ボタンを押してください。",
    "MSGI014":"移動入庫未処理分を全て選択中ロケーションに一括で移動入庫登録します。\n続行する場合は「登録」ボタンを押してください。",
    "MSGI015":"棚卸データの作成が完了しました。",
    "MSGI016":"%1しました。",
    "MSGI017":"%1してもよろしいですか？",
    "MSGI018":"今回の変更内容は反映されません。変更前の数に戻りますが、よろしいですか？",
    "MSGI019":"エラーはありません。",
    "MSGI020":"%1を%2しますか？",
    "MSGI021":"%1を%2しました。",
    "MSGI022":"%1を%2します。",
    "MSGI023":"%1は、システム未対応のため荷札発行されません。",
    "MSGW001":"システムエラーが発生しました。",
    "MSGW002":"%1は必須入力項目です。",
    "MSGW003":"%1は1つ以上設定してください。",
    "MSGW004":"%1は%2桁以下で入力してください。",
    "MSGW005":"%1が%2に存在しません。",
    "MSGW006":"入力された%1は既に存在します。",
    "MSGW007":"変更を保存できません。%1は更新されています。",
    "MSGW008":"%1は%2桁で入力してください。",
    "MSGW009":"%1は%2で入力してください。",
    "MSGW010":"%1は%2が必須です。",
    "MSGW011":"%1と%2が一致しません。",
    "MSGW012":"%1の形式が正しくありません。",
    "MSGW013":"%1に誤りがあります。",
    "MSGW014":"ユーザーIDはご利用できません。管理者にお問い合わせください。",
    "MSGW015":"%1は既に%2されています。",
    "MSGW016":"%1が正しくありません。",
    "MSGW017":"ログインの認証エラーが制限を超えたため、ログインできません。「パスワードを忘れた方はこちら」からパスワードのリセットを行ってください。",
    "MSGW018":"メールアドレスが登録されていません。管理者にお問い合わせください。",
    "MSGW019":"過去3回以内に使用したパスワードは使用できません。",
    "MSGW020":"変更を保存できません。%1は%2が編集中です。",
    "MSGW021":"%1は%2を指定してください。",
    "MSGW022":"選択されていない必須の%1があります。",
    "MSGW023":"%1行目の%2が空白です。",
    "MSGW024":"%1行目の%2は%3桁以下で入力してください。",
    "MSGW025":"%1行目の%2は%3で入力してください。",
    "MSGW026":"%1か%2の何れかを入力してください。",
    "MSGW027":"%1を選択してください。",
    "MSGW028":"%1が%2を超えています。",
    "MSGW029":"%1がありません。",
    "MSGW030":"URLの有効期限が切れています。",
    "MSGW031":"無効な操作が行われました。",
    "MSGW032":"削除する営業所/寄託者は一般ユーザーで使用されているため削除できません。",
    "MSGW033":"1つ前の画面に戻り、操作をやり直してください。",
    "MSGW034":"過去5回以内に使用したパスワードは使用できません。",
    "MSGW035":"アップロードできる件数は1D/Oにつき500件までです。",
    "MSGW036":"アップロードできる件数は10,000件までです。",
    "MSGW037":"排他エラー。%1が作業中です。",
    "MSGW038":"ユーザーIDがロックされています。情報システム部に連絡してください。",
    "MSGW039":"パスワード変更に問題があります。情報システム部に連絡してください。",
    "MSGW040":"操作する権限がありません。情報システム部に連絡してください。",
    "MSGW041":"長時間操作が行われなかったため、自動的にログアウトされました。",
    "MSGW042":"寄託者情報が存在していません。情報システム部に連絡してください。",
    "MSGW043":"営業所コード: %1/ユーザーID: %2に対応したグループ管理ファイルが存在していません。データ不整合があります。",
    "MSGW044":"ユーザー管理ファイルの更新に失敗しました。(ユーザID: %1)",
    "MSGW045":"ユーザー別の利用可能営業所、寄託者情報が存在していません。データ不整合があります。",
    "MSGW046":"ユーザー別の利用可能権限情報が存在していません。データ不整合があります。",
    "MSGW047":"ユーザーIDまたはパスワードが正しくありません。",
    "MSGW048":"ユーザー別権限に設定された情報が、機能ID定義ファイルに登録されていません。データ不整合があります。",
    "MSGW049":"ユーザー情報が存在していません。データ不整合があります。",
    "MSGW050":"現行パスワードが正しくありません。",
    "MSGW051":"パスワードが正しくありません。",
    "MSGW052":"新パスワードがポリシーに一致していません。",
    "MSGW053":"%1できません。",
    "MSGW054":"すでに%1のため、%2することができません。",
    "MSGW055":"アップロードできる件数は50件までです。\nそれ以上の場合は、一括アップロード機能をご利用ください。",
    "MSGW056":"同じ入庫予定日、伝票番号のデータが既に存在します。",
    "MSGW057":"欠品があります。",
    "MSGW058":"扱業者が未設定です。",
    "MSGW059":"荷口数未設定のため、荷札発行されません。",
    "MSGE001":"%1件エラーが発生しました。",
    "MSGW063":"担当者メールアドレスが存在していません。システム管理者にお問い合わせください。",
    "MSGW064":"%1行目の%2について、選択した%3を入力してください。",
    "MSGW065":"22:00~6:00の間は入庫予定登録、出荷指示登録機能はご利用いただけません。",
    "MSGW068":"申請されたユーザーIDは既に使用されています。営業所担当者のユーザーID登録準備からやり直してください。",
    "MSGW069":"申請されたユーザーIDは既に使用されています。寄託者ユーザーID確認メニューよりご確認ください。",
    "EMPTY":"%1",
    "MSGW076": "同一D/Oがまとまっていません。",
    "MSGW077": "ログインエラー。%1",
    "MSGCSV": "CSVファイルを作成しました。",
    "MSGUPDATE": "入力内容を更新しました。",
    "MSGBREAK": "入力内容を破棄しました。",
    "MSGREGIST": "入力内容を登録しました。",
    "MSGAPPLOAD": "アップロードが完了しました。",
    "MSGEMPTYMGMTNUM": "管理番号を入力してください。",
    "MSGEMPTYALL": "入庫情報を入力してください。",
    "MSGW078": "%1が制限を超えています。",
};
export const messageBoxJSON = {
    "MSBJ001":"登録しますか？",
    "MSBJ002":"変更しますか？",
    "MSBJ003":"保存されたデータはクリアされますがよろしいですか？",
    "MSBJ004":"代表ユーザー変更申請を行うと既存の代表ユーザーは利用できなくなります\n代表ユーザー変更申請を行いますか？",
    "MSBJ005":"送信しますか？",
    "MSBJ006":"利用停止を行うとすべてのユーザー（管理、一般を含む）が利用できなくなります。\n利用停止後に再度WEB文書システムを利用する場合、再度ユーザー登録が必要となります。\nWEB文書システムの利用停止申請を行いますか？",
    "MSBJ007":"選択したユーザーを削除しますか？",
    "MSBJ008":"このアップロード項目定義を削除しますか？",
    "MSBJ009":"項目をアップロードしますか？",
    "MSBJ010":"設定内容を保存しますか？",
    "MSBJ011":"出荷指示個数（端数）が未引当個数（端数）を超えています。\n登録しますか？"

};

export function messageFormat (msgId: string | number, ...args: any[]) {
    let msg = messageJSON[msgId]
    
    for (let i = 0; i < args.length; i++) {
      const index = i + 1
      const regexp = new RegExp('\\%' + index, 'gi')

      msg = msg.replace(regexp, args[i])
    }
    return msg
  }

export const messageMap = new Map(Object.entries(messageJSON));